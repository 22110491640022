import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const PostBranchesApi = async (data) => {
  try {
    const result = await fetch(`${apilUrl}/branches`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al crear la sucursal.')
  }
}

export const GetBranchesApi = async (companyId) => {
  try {
    let result = await fetch(`${apilUrl}/branches/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener las sucursales.')
  }
}

export const updateBranchesApi = async (branchData, branchId) => {
  try {
    let result = await fetch(`${apilUrl}/branches/${parseInt(branchId)}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(branchData),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al actualizar la sucursal.')
  }
}
