import { apilUrl } from '../../services/url'
import { dateFormat, notify } from '../../services/utils'

export const getExpirationDatesApi = async (companyId) => {
  try {
    let result = await fetch(`${apilUrl}/expiration-dates/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener las fechas de vencimiento.')
  }
}

export const updateExpirationDatesApi = async (expirationDate, companyId) => {
  try {
    let clientData = {
      expirationDate: dateFormat(
        new Date(expirationDate).toLocaleDateString(),
        false,
        true,
      ),
    }
    let result = await fetch(`${apilUrl}/expiration-dates/${companyId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientData),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al actualizar la fecha de vencimiento.')
  }
}
