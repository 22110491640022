import { apilUrl } from './url'

export const verifiedToken = async () => {
  try {
    let result = await fetch(`${apilUrl}/verified-token`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify('registerData'),
    }).then((response) => response.json())

    if (result.data == true) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}
