import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const GetDebitNoteNumerationApi = async (DebitNoteNumerationId) => {
  try {
    let result = await fetch(
      `${apilUrl}/debit-note-numeration/${DebitNoteNumerationId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener la numeración de la nota de débito.')
  }
}

export const UpdateDebitNoteNumerationApi = async (
  DebitNoteNumerationId,
  numerationStart,
  prefix,
) => {
  try {
    let numerationData = {
      id: DebitNoteNumerationId,
      numerationStart: parseInt(numerationStart),
      prefix: prefix.toUpperCase(),
    }
    let result = await fetch(
      `${apilUrl}/debit-note-numeration/${DebitNoteNumerationId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(numerationData),
      },
    ).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al actualizar la numeración de la nota de débito.')
  }
}
