import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const GetCompaniesApi = async () => {
  try {
    const result = await fetch(`${apilUrl}/companies`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener las compañias.')
  }
}


