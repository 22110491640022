import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const getClientUsersApi = async () => {
  try {
    let result = await fetch(`${apilUrl}/client-users`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener los usuarios.')
  }
}

export const setClientUserActiveApi = async (active, userId) => {
  try {
    let result = await fetch(`${apilUrl}/client-users/active/${userId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ active }),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al cambiar estado del usuario.')
  }
}
