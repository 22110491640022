import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/admin/menu'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Edit } from 'tabler-icons-react'
import { CirclePlus } from 'tabler-icons-react'
import Badge from 'react-bootstrap/Badge'

import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { verifiedToken } from '../../services/token.service'
import adminDecodeToken from '../../services/adminDecodeToken.service'
import useStore from '../../components/zustand'
import {
  GetBranchesApi,
  PostBranchesApi,
  updateBranchesApi,
} from '../api/branches'
import { GetCompaniesApi } from '../api/companies'
import AdminMenus from '../../components/admin/adminMenus'

export default function Branches() {
  const navigate = useNavigate()

  //Clients Companies
  const [companies, setCompanies] = useState([])

  //Clients Companies Branches
  const [branches, setBranches] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //COMPANY ID
  const [companyId, setCompanyId] = React.useState(0)
  const [currentCompany, setCurrentCompany] = React.useState({})

  const form = useForm({
    initialValues: {
      branchName: '',
      branchAddress: '',
      branchPhone: '',
      active: false,
    },
  })

  const putForm = useForm({
    initialValues: {
      branchId: 0,
      branchName: '',
      branchAddress: '',
      branchPhone: '',
      active: false,
    },
  })

  const restartConstants = () => {
    form.setFieldValue('branchName', '')
    form.setFieldValue('branchAddress', '')
    form.setFieldValue('branchPhone', '')
    form.setFieldValue('active', false)
  }

  //GETS HOSE FIELDS
  const getBranchData = async (
    branchId,
    branchName,
    branchAddress,
    branchPhone,
    active,
  ) => {
    putForm.setFieldValue('branchId', branchId)
    putForm.setFieldValue('branchName', branchName)
    putForm.setFieldValue('branchAddress', branchAddress)
    putForm.setFieldValue('branchPhone', branchPhone)
    putForm.setFieldValue('active', active)
    setModalPutShow(true)
  }

  const successCreation = async (data) => {
    if (data == 'Branches created') {
      notify('success', 'Creación Exitoso')

      await getClientCompanyBranches(companyId)
      restartConstants()
      setModalCreateShow(false)
    } else {
      notify('warning', 'Error al crear')
    }
  }

  const createCompanyBranch = async () => {
    let readyToCreate = true
    if (form.values.branchName == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre de la sucursal.')
    }
    if (form.values.branchAddress == '') {
      readyToCreate = false
      notify('warning', 'Falta la dirección.')
    }
    if (form.values.branchPhone == '') {
      readyToCreate = false
      notify('warning', 'Falta el número telefónico.')
    }

    if (readyToCreate) {
      let branchData = {
        companyId: parseInt(companyId),
        name: form.values.branchName,
        address: form.values.branchAddress,
        phone: form.values.branchPhone,
        active: form.values.active,
      }
      let result = await PostBranchesApi(branchData)
      if (result.data != null) successCreation(result.data)
      else notify('warning', 'Error al crear la sucursal,verifique sus datos.')
    }
  }

  const getClientCompanies = async () => {
    let result = await GetCompaniesApi()
    if (result.data != null) setCompanies(result.data)
    else notify('warning', 'No hay empresas para este usuario.')
  }

  const handleCompanyId = async (companyId) => {
    setCompanyId(companyId)
    setCurrentCompany(companies.find((x) => x.id == companyId))
    await getClientCompanyBranches(companyId)
  }

  const handleOpenCreateModal = async () => {
    if (companyId <= 0) {
      notify('warning', 'Por favor selecciona una empresa.')
    } else {
      setModalCreateShow(true)
    }
  }

  const getClientCompanyBranches = async (companyId) => {
    let result = await GetBranchesApi(companyId)
    if (result.data != null) setBranches(result.data)
    else {
      notify('warning', 'No hay sucursales para esta empresa.')
      setBranches([])
    }
  }

  const updateBranch = async () => {
    let readyToUpdate = true
    if (putForm.values.branchName == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre de la sucursal.')
    }
    if (putForm.values.branchAddress == '') {
      readyToUpdate = false
      notify('warning', 'Falta la dirección.')
    }
    if (putForm.values.branchPhone == '') {
      readyToUpdate = false
      notify('warning', 'Falta el número telefónico.')
    }

    if (readyToUpdate) {
      let branchData = {
        name: putForm.values.branchName,
        address: putForm.values.branchAddress,
        phone: putForm.values.branchPhone,
        active: putForm.values.active,
      }
      let result = await updateBranchesApi(branchData, putForm.values.branchId)
      if (result.data == 'Branches updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getClientCompanyBranches(companyId)
      } else notify('warning', 'Error al actualizar')
    }
  }

  const handleActive = (active) => {
    form.setFieldValue('active', active)
  }

  const handleActiveEdit = (active) => {
    putForm.setFieldValue('active', active)
  }

  const formatActive = (active) => {
    if (active) {
      return <Badge bg="primary">Activo</Badge>
    } else {
      return <Badge bg="secondary">Inactivo</Badge>
    }
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()
    if (result == false) {
      navigate('/')
    } else {
      await getClientCompanies()
    }

    // check if user have admin rights
    let adminToken = await adminDecodeToken()
    if (adminToken.roleId != 1) {
      navigate('/')
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container>
        <AdminMenus buttonId={1} />
        <br></br>
        {/* <h2>Manejo de sucursales</h2> */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Selecciona una empresa
          </InputLabel>
          <MuiSelect
            value={companyId}
            label="Seleciona Empresa"
            onChange={(e) => handleCompanyId(e.target.value)}
          >
            {companies.map((company, index) => {
              return (
                <MenuItem value={company.id} key={index}>
                  {company.name}
                </MenuItem>
              )
            })}
          </MuiSelect>
        </FormControl>
        <br></br>
        {companyId !== 0 ? (
          <Container>
            <Button
              variant="primary"
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => handleOpenCreateModal()}
            >
              Nueva Sucursal <CirclePlus size={30} color="#FFFFFF" />
            </Button>
            <br></br>
            <br></br>
            <Row xs={1} md={4} className="g-4">
              {branches.map((branch, index) => {
                return (
                  <Col key={index}>
                    <Paper
                      withBorder
                      radius="md"
                      p="xs"
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Group>
                        <div>
                          <Text
                            color="dimmed"
                            size="xl"
                            transform="uppercase"
                            weight={700}
                          >
                            {branch.name}
                          </Text>
                          <Text
                            color="dimmed"
                            size="md"
                            transform="uppercase"
                            weight={700}
                          >
                            {currentCompany.name}
                          </Text>
                          <Text
                            color="dimmed"
                            size="md"
                            transform="uppercase"
                            weight={700}
                          ></Text>
                          {formatActive(branch.active)}
                        </div>
                      </Group>
                      <Group
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '0 0 0 auto',
                        }}
                      >
                        <Button
                          style={{
                            float: 'right',
                            backgroundColor: 'transparent',
                          }}
                          onClick={() =>
                            getBranchData(
                              branch.id,
                              branch.name,
                              branch.address,
                              branch.phone,
                              branch.active,
                            )
                          }
                        >
                          <Edit size={30} color="#000" />
                        </Button>
                      </Group>
                    </Paper>
                  </Col>
                )
              })}
            </Row>
          </Container>
        ) : null}
      </Container>
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva Sucursal
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombre de la sucursal"
                      placeholder="Nombre de la sucursal"
                      value={form.values.branchName}
                      onChange={(event) =>
                        form.setFieldValue(
                          'branchName',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Dirección"
                      placeholder="Dirección"
                      value={form.values.branchAddress}
                      onChange={(event) =>
                        form.setFieldValue(
                          'branchAddress',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Tel/Cel"
                      placeholder="Tel/Cel"
                      value={form.values.branchPhone}
                      onChange={(event) => {
                        form.setFieldValue(
                          'branchPhone',
                          event.currentTarget.value,
                        )
                      }}
                    />

                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Activar/Desactivar
                      </InputLabel>
                      <MuiSelect
                        label="Activar/Desactivar"
                        onChange={(e) => handleActive(e.target.value)}
                        value={form.values.active}
                      >
                        <MenuItem disabled></MenuItem>
                        <MenuItem value={true}>Activo</MenuItem>
                        <MenuItem value={false}>Inactivo</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createCompanyBranch()}
              >
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalCreateShow(false)
                  restartConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombre de la sucursal"
                      placeholder="Nombre de la sucursal"
                      value={putForm.values.branchName}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'branchName',
                          event.currentTarget.value,
                        )
                      }
                    />

                    <TextField
                      variant="standard"
                      required
                      label="Dirección"
                      placeholder="Dirección"
                      value={putForm.values.branchAddress}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'branchAddress',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Tel/Cel"
                      placeholder="Tel/Cel"
                      value={putForm.values.branchPhone}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'branchPhone',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Activar/Desactivar
                      </InputLabel>
                      <MuiSelect
                        label="Activar/Desactivar"
                        onChange={(e) => handleActiveEdit(e.target.value)}
                        value={putForm.values.active}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={true}>Activo</MenuItem>
                        <MenuItem value={false}>Inactivo</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateBranch()}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <br></br>
    </div>
  )
}
