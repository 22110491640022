import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/admin/menu'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import { Edit } from 'tabler-icons-react'
import { CirclePlus } from 'tabler-icons-react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from 'mdb-react-ui-kit'
import { verifiedToken } from '../../services/token.service'
import adminDecodeToken from '../../services/adminDecodeToken.service'
import useStore from '../../components/zustand'
import { GetBranchesApi } from '../api/branches'
import { GetCompaniesApi } from '../api/companies'
import {
  GetResolutionsApi,
  PostResolutionsApi,
  UpdateResolutionsApi,
} from '../api/resolutions'
import AdminMenus from '../../components/admin/adminMenus'

export default function Resolutions() {
  const navigate = useNavigate()

  const [companies, setCompanies] = useState([])
  const [branches, setBranches] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //COMPANY ID
  const [currentBranch, setCurrentBranch] = React.useState({})
  const [companyId, setCompanyId] = React.useState(0)
  const [branchId, setBranchId] = React.useState(0)

  const form = useForm({
    initialValues: {
      Prefix: '',
      ResolutionNumber: '',
      Start: 0,
      End: 0,
      DataicoAccountId: '',
      DataicoAuthToken: '',
      endDate: dayjs(new Date()),
    },
  })

  const putForm = useForm({
    initialValues: {
      id: 0,
      Prefix: '',
      ResolutionNumber: '',
      Start: 0,
      End: 0,
      DataicoAccountId: '',
      DataicoAuthToken: '',
      endDate: dayjs(new Date()),
    },
  })

  const resetConstants = () => {
    form.setFieldValue('id', 0)
    form.setFieldValue('Prefix', '')
    form.setFieldValue('ResolutionNumber', '')
    form.setFieldValue('Start', 0)
    form.setFieldValue('End', 0)
    form.setFieldValue('DataicoAccountId', '')
    form.setFieldValue('DataicoAuthToken', '')
    form.setFieldValue('endDate', dayjs(new Date()))
  }

  //-----------------resolutions--------------

  const [resolutions, setResolutions] = useState([])

  const getResolutions = async (branchId) => {
    let result = await GetResolutionsApi(branchId)
    if (result.data != null) setResolutions(result.data)
    else {
      notify('warning', 'No hay resoluciones para esta sucursal.')
      setResolutions([])
    }
  }

  const createResolution = async () => {
    let readyToCreate = true
    if (form.values.Prefix == '') {
      readyToCreate = false
      notify('warning', 'Falta el prefijo de la numeración.')
    }
    if (form.values.ResolutionNumber == '') {
      readyToCreate = false
      notify('warning', 'Falta el número de resolución DIAN.')
    }
    if (form.values.Start < 0) {
      readyToCreate = false
      notify('warning', 'Falta el inicio de factura.')
    }
    if (form.values.End < 0) {
      readyToCreate = false
      notify('warning', 'Falta el final de factura.')
    }
    if (form.values.DataicoAccountId == '') {
      readyToCreate = false
      notify('warning', 'Falta el id de cuenta DATAICO.')
    }
    if (form.values.DataicoAuthToken == '') {
      readyToCreate = false
      notify('warning', 'Falta el token de cuenta DATAICO.')
    }

    if (parseInt(form.values.Start) > parseInt(form.values.End)) {
      readyToCreate = false
      notify('warning', 'El inicio de factura no debe ser mayor que el final.')
    }

    if (readyToCreate) {
      let resolutionData = {
        companyId: currentBranch.companyId,
        branchId: currentBranch.id,
        prefix: form.values.Prefix,
        resolutionNumber: form.values.ResolutionNumber,
        start: parseInt(form.values.Start),
        end: parseInt(form.values.End),
        dataicoAccountId: form.values.DataicoAccountId,
        AuthToken: form.values.DataicoAuthToken,
        EndDate: new Date(form.values.endDate),
      }
      let result = await PostResolutionsApi(resolutionData)

      if (result.data == 'Resolution created') {
        notify('success', 'Creación Exitoso')
        getResolutions(currentBranch.id)
        setModalCreateShow(false)
        resetConstants()
      } else {
        notify('warning', 'No se pudo crear la resolución.')
      }
    }
  }

  const updateResolution = async () => {
    let readyToUpdate = true
    if (putForm.values.Prefix == '') {
      readyToUpdate = false
      notify('warning', 'Falta el prefijo de la numeración.')
    }
    if (putForm.values.ResolutionNumber == '') {
      readyToUpdate = false
      notify('warning', 'Falta el número de resolución DIAN.')
    }
    if (putForm.values.Start < 0) {
      readyToUpdate = false
      notify('warning', 'Falta el inicio de factura.')
    }
    if (putForm.values.End < 0) {
      readyToUpdate = false
      notify('warning', 'Falta el final de factura.')
    }
    if (putForm.values.DataicoAccountId == '') {
      readyToUpdate = false
      notify('warning', 'Falta el id de cuenta DATAICO.')
    }
    if (putForm.values.DataicoAuthToken == '') {
      readyToUpdate = false
      notify('warning', 'Falta el token de cuenta DATAICO.')
    }

    if (parseInt(putForm.values.Start) > parseInt(putForm.values.End)) {
      notify('warning', 'El inicio de factura no debe ser mayor que el final.')
      readyToUpdate = false
    }

    if (readyToUpdate) {
      let resolutionData = {
        companyId: currentBranch.companyId,
        branchId: currentBranch.id,
        prefix: putForm.values.Prefix,
        resolutionNumber: putForm.values.ResolutionNumber,
        start: parseInt(putForm.values.Start),
        end: parseInt(putForm.values.End),
        dataicoAccountId: putForm.values.DataicoAccountId,
        AuthToken: putForm.values.DataicoAuthToken,
        EndDate: new Date(putForm.values.endDate),
      }
      let result = await UpdateResolutionsApi(resolutionData, putForm.values.id)
      if (result.data === 'Resolution updated') {
        notify('success', 'Actualización Exitoso')
        getResolutions(currentBranch.id)
        setModalPutShow(false)
      } else notify('warning', 'No se pudo actualizar la resolución.')
    }
  }

  const handleUpdate = async (resolution) => {
    putForm.setFieldValue('id', resolution.id)
    putForm.setFieldValue('Prefix', resolution.prefix)
    putForm.setFieldValue('ResolutionNumber', resolution.resolutionNumber)
    putForm.setFieldValue('Start', resolution.start)
    putForm.setFieldValue('End', resolution.end)
    putForm.setFieldValue('DataicoAccountId', resolution.dataicoAccountId)
    putForm.setFieldValue('DataicoAuthToken', resolution.authToken)
    putForm.setFieldValue('endDate', dayjs(new Date(resolution.endDate)))
    setModalPutShow(true)
  }

  //--------companies and branches------------

  const getClientCompanies = async () => {
    let result = await GetCompaniesApi()
    if (result.data != null) setCompanies(result.data)
    else notify('warning', 'No se encuentran empresas.')
  }

  const getClientBranches = async (companyId) => {
    let result = await GetBranchesApi(companyId)
    if (result.data != null) setBranches(result.data)
    else notify('warning', 'No se encuentran sucursales.')
  }

  const handleCompanyId = (companyId) => {
    setBranches([])
    setResolutions([])
    setCompanyId(companyId)
    getClientBranches(companyId)
  }

  const handleBranchId = (branchId) => {
    setBranchId(branchId)
    getResolutions(branchId)
    let currentBranch = branches.find((branch) => branch.id == branchId)
    setCurrentBranch(currentBranch)
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()
    if (result == false) {
      navigate('/')
    } else {
      await getClientCompanies()
    }

    // check if user have admin rights
    let adminToken = await adminDecodeToken()
    if (adminToken.roleId != 1) {
      navigate('/')
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Container>
        <AdminMenus buttonId={2} />
        <br></br>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Selecciona una empresa
          </InputLabel>
          <MuiSelect
            value={companyId}
            label="Selecione una empresa"
            onChange={(e) => handleCompanyId(e.target.value)}
          >
            {companies.map((company, index) => {
              return (
                <MenuItem value={company.id} key={index}>
                  {company.name}
                </MenuItem>
              )
            })}
          </MuiSelect>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Selecciona una sucursal
          </InputLabel>
          <MuiSelect
            disabled={branches.length <= 0}
            value={branchId}
            label="Selecione una sucursal"
            onChange={(e) => handleBranchId(e.target.value)}
          >
            {branches.map((branch, index) => {
              return (
                <MenuItem value={branch.id} key={index}>
                  {branch.name}
                </MenuItem>
              )
            })}
          </MuiSelect>
        </FormControl>
        <br></br>
        {branchId !== 0 ? (
          <Container>
            <Button
              variant="primary"
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => setModalCreateShow(true)}
            >
              Nueva resolución <CirclePlus size={30} color="#FFFFFF" />
            </Button>
            <br></br>
            <br></br>
            <MDBTable>
              <MDBTableHead>
                <tr>
                  <td>Prefijo</td>
                  <td>Número de resolución</td>
                  <td>Inicio de factura</td>
                  <td>Fin de factura</td>
                  <td>Acciones</td>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {resolutions.map((resolution, index) => {
                  return (
                    <tr key={index}>
                      <td>{resolution.prefix}</td>
                      <td>{resolution.resolutionNumber}</td>
                      <td>{resolution.start}</td>
                      <td>{resolution.end}</td>
                      <td>
                        <Button
                          style={{
                            float: 'right',
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => handleUpdate(resolution)}
                        >
                          <Edit size={25} color="#000000" />
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </MDBTableBody>
            </MDBTable>
          </Container>
        ) : null}
      </Container>
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva Resolución
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Prefijo"
                      placeholder="Prefijo"
                      value={form.values.Prefix}
                      onChange={(event) => {
                        form.setFieldValue('Prefix', event.currentTarget.value)
                      }}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Número de resolución"
                      placeholder="Número de resolución"
                      value={form.values.ResolutionNumber}
                      onChange={(event) =>
                        form.setFieldValue(
                          'ResolutionNumber',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <div>
                      <label>Fecha de vencimiento</label>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ES"
                      >
                        <DatePicker
                          locale="es"
                          format="DD/MM/YYYY"
                          value={form.values.endDate}
                          disablePast
                          onChange={(newValue) =>
                            form.setFieldValue('endDate', newValue)
                          }
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Inicio de factura"
                      placeholder="Inicio de factura"
                      value={form.values.Start}
                      onChange={(event) =>
                        form.setFieldValue('Start', event.currentTarget.value)
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Fin de factura"
                      placeholder="Fin de factura"
                      value={form.values.End}
                      onChange={(event) =>
                        form.setFieldValue('End', event.currentTarget.value)
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Dataico Account Id"
                      placeholder="Dataico Account Id"
                      value={form.values.DataicoAccountId}
                      onChange={(event) =>
                        form.setFieldValue(
                          'DataicoAccountId',
                          event.currentTarget.value,
                        )
                      }
                    />

                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Dataico Auth Token"
                      placeholder="Dataico Auth Token"
                      value={form.values.DataicoAuthToken}
                      onChange={(event) =>
                        form.setFieldValue(
                          'DataicoAuthToken',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createResolution()}
              >
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalCreateShow(false)
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando Resolución
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Prefijo"
                      placeholder="Prefijo"
                      value={putForm.values.Prefix}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'Prefix',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Número de resolución"
                      placeholder="Número de resolución"
                      value={putForm.values.ResolutionNumber}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'ResolutionNumber',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <div>
                      <label>Fecha de vencimiento</label>

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ES"
                      >
                        <DatePicker
                          locale="es"
                          format="DD/MM/YYYY"
                          value={putForm.values.endDate}
                          disablePast
                          onChange={(newValue) =>
                            putForm.setFieldValue('endDate', newValue)
                          }
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Inicio de factura"
                      placeholder="Inicio de factura"
                      value={putForm.values.Start}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'Start',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Fin de factura"
                      placeholder="Fin de factura"
                      value={putForm.values.End}
                      onChange={(event) =>
                        putForm.setFieldValue('End', event.currentTarget.value)
                      }
                    />
                  </Group>

                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Dataico Account Id"
                      placeholder="Dataico Account Id"
                      value={putForm.values.DataicoAccountId}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'DataicoAccountId',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Dataico Auth Token"
                      placeholder="Dataico Auth Token"
                      value={putForm.values.DataicoAuthToken}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'DataicoAuthToken',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateResolution()}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <br></br>
    </div>
  )
}
