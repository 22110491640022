import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/admin/menu'
import Container from 'react-bootstrap/Container'
import { Group } from '@mantine/core'
import Toggle from 'react-toggle'
import Button from 'react-bootstrap/Button'
import { Clock, FileInvoice } from 'tabler-icons-react'
import { companyUrl } from '../../services/url'
import { verifiedToken } from '../../services/token.service'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import adminDecodeToken from '../../services/adminDecodeToken.service'
import { useForm } from '@mantine/hooks'
import { dateFormat } from '../../services/utils'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { notify } from '../../services/utils'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import useStore from '../../components/zustand'
import { getClientUsersApi, setClientUserActiveApi } from '../api/clientUsers'
import {
  getExpirationDatesApi,
  updateExpirationDatesApi,
} from '../api/expirationdates'
import AdminMenus from '../../components/admin/adminMenus'

export default function Clients() {
  const navigate = useNavigate()

  //Clients users
  const [clients, setClients] = useState([])

  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [companyId, setCompanyId] = useState(0)

  const putForm = useForm({
    initialValues: {
      expirationDate: dayjs(new Date()),
    },
  })

  const getAllClientUsers = async () => {
    let result = await getClientUsersApi()
    if (result.data != null) setClients(result.data)
    else notify('warning', 'No se encontraron clientes.')
  }

  const putAllClientUsersActive = async (id, active) => {
    let result = await setClientUserActiveApi(active, id)
    if (result.data === 'Client Activated') await getAllClientUsers()
    else notify('warning', 'No se pudo cambiar el estado del cliente.')
  }

  const editClient = async (companyId) => {
    setCompanyId(companyId)
    await getExpirationDate(companyId)
    setModalPutShow(true)
  }

  const handleActive = async (id, active) => {
    if (active == true) {
      await putAllClientUsersActive(id, false)
    } else {
      await putAllClientUsersActive(id, true)
    }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const getExpirationDate = async (companyId) => {
    let result = await getExpirationDatesApi(companyId)
    if (result.data != null) {
      let date = result.data[0].expirationDate.replace('-', '/')
      putForm.setFieldValue('expirationDate', dayjs(new Date(date)))
    } else notify('warning', 'No se encontró la fecha de expiración.')
  }

  const updateClient = async () => {
    let result = await updateExpirationDatesApi(
      putForm.values.expirationDate,
      companyId,
    )
    if (result.data === 'ExpirationDates updated') {
      setModalPutShow(false)
      notify('success', 'Suscripción actualizada satisfactoriamente.')
    } else notify('warning', 'No se pudo actualizar la suscripción.')
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()

    if (result == false) {
      navigate('/')
    } else {
      await getAllClientUsers()
    }

    // check if user have admin rights
    let adminToken = await adminDecodeToken()
    if (adminToken.roleId != 1) {
      navigate('/')
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container>
        <AdminMenus buttonId={0} />
        <br></br>
        <MDBTable small>
          <MDBTableHead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Empresa</th>
              <th>Rut (PDF)</th>
              <th>Cámara (PDF)</th>
              <th>Cédula (PDF)</th>
              <th>Activo</th>
              <th>Suscripción</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {clients.map((client, index) => {
              return (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{client.fullName}</td>
                  <td>{client.email}</td>
                  <td>{client.name}</td>
                  <td style={{ width: '10px', height: '10px' }}>
                    <a
                      onClick={() => openInNewTab(companyUrl + client.rutFile)}
                    >
                      <FileInvoice
                        style={{
                          float: 'right',
                          float: 'inline-start',
                          alignContent: 'center',
                          cursor: 'pointer',
                        }}
                        size={40}
                        color="#EF5350"
                        strokeWidth={1.2}
                      />
                    </a>
                  </td>
                  <td style={{ width: '10px', height: '10px' }}>
                    <a
                      onClick={() =>
                        openInNewTab(companyUrl + client.camaraDeComercioFile)
                      }
                    >
                      <FileInvoice
                        style={{
                          float: 'right',
                          float: 'inline-start',
                          alignContent: 'center',
                          cursor: 'pointer',
                        }}
                        size={40}
                        color="#EF5350"
                        strokeWidth={1.2}
                      />
                    </a>
                  </td>
                  <td style={{ width: '10px', height: '10px' }}>
                    <a
                      onClick={() =>
                        openInNewTab(
                          companyUrl + client.cedulaDeRepresentanteile,
                        )
                      }
                    >
                      <FileInvoice
                        style={{
                          float: 'right',
                          float: 'inline-start',
                          alignContent: 'center',
                          cursor: 'pointer',
                        }}
                        size={40}
                        color="#EF5350"
                        strokeWidth={1.2}
                      />
                    </a>
                  </td>
                  <td>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={client.active}
                      onChange={() => handleActive(client.id, client.active)}
                    />
                  </td>
                  <td>
                    <Button
                      style={{ backgroundColor: 'transparent' }}
                      onClick={() => editClient(client.companyId)}
                    >
                      <Clock size={25} color="#000" />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </MDBTableBody>
        </MDBTable>
      </Container>
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando cliente
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <h5>Fecha de Expiración Actual: </h5>
                  <h6>
                    {dateFormat(
                      new Date(
                        putForm.values.expirationDate,
                      ).toLocaleDateString(),
                      true,
                      false,
                    )}
                  </h6>
                  <div>
                    <label>Nueva fecha de expiración:</label>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ES"
                    >
                      <DatePicker
                        locale="es"
                        format="DD/MM/YYYY"
                        disablePast
                        value={putForm.values.expirationDate}
                        onChange={(newValue) =>
                          putForm.setFieldValue('expirationDate', newValue)
                        }
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </LocalizationProvider>
                  </div>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClient(companyId)}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
