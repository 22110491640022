import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const GetResolutionsApi = async (branchId) => {
  try {
    let result = await fetch(`${apilUrl}/resolutions/all/${branchId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener las resoluciones.')
  }
}

export const GetResolutionsByIdApi = async (resolutionId) => {
  try {
    let result = await fetch(`${apilUrl}/resolutions/${resolutionId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al obtener las resoluciones.')
  }
}

export const PostResolutionsApi = async (data) => {
  try {
    let result = await fetch(`${apilUrl}/resolutions`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al crear la resolución.')
  }
}

export const UpdateResolutionsApi = async (data, resolutionId) => {
  try {
    let result = await fetch(`${apilUrl}/resolutions/${resolutionId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al actualizar la resolución.')
  }
}
