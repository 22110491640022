import { React } from 'react'
import { Routes, HashRouter, Route } from 'react-router-dom'
import AdminDashboard from './pages/admin/dashboard.js'
import AdminLogin from './pages/admin/login.js'
import AdminBranches from './pages/admin/branches.js'
import AdminClients from './pages/admin/clients.js'
import AdminCreditNote from './pages/admin/creditnote.js'
import AdminDebitNote from './pages/admin/debitnote.js'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toggle/style.css'

import useStore from './components/zustand'
import { ThemeProvider, createTheme } from '@mui/material'
import Resolutions from './pages/admin/resolutions.js'

const App = () => {
  const { isDarkModeActive } = useStore()

  return (
    <ThemeProvider
      theme={createTheme({
        palette: { mode: isDarkModeActive ? 'dark' : 'light' },
      })}
    >
      <div className="App">
        <div style={{ width: '100%' }}>
          <HashRouter>
            <Routes>
              <Route path="/" element={<AdminLogin />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/branches" element={<AdminBranches />} />
              <Route path="/resolutions" element={<Resolutions />} />
              <Route path="/clients" element={<AdminClients />} />
              <Route path="/creditnote" element={<AdminCreditNote />} />
              <Route path="/debitnote" element={<AdminDebitNote />} />
            </Routes>
          </HashRouter>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
