export const apilUrl = process.env.REACT_APP_API_URL

//FILES URL
export const signatureUrl = 'https://files.avrilapp.com/files/signature/'
export const moneySignatureUrl =
  'https://files.avrilapp.com/files/moneysignature/'
export const companyUrl = 'https://files.avrilapp.com/files/company/'

//PRODUCTION SERVER
// export const apilUrl = 'http://18.212.90.96:3000';

//WEBSOCKET URL
// export const wslUrl = 'ws://localhost:3000/ws';
//WEBSOCKET URL SERVER
// export const wslUrl = 'ws://18.212.90.96:3000/ws';
