import React from 'react'
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import companyLogo from '../../resources/images/companyLogo.png'
import { Moon, Sun } from 'tabler-icons-react'
import useStore from '../zustand'

export default function LoginHeader() {

  const {
    isDarkModeActive,
    darkMode,
    setIsDarkModeActive,
  } = useStore()


  const handleDarkMode = () => {
    darkMode.toggle()
    setIsDarkModeActive(!isDarkModeActive)
  }
  

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="lg"
      style={{ backgroundColor: '#212121' }}
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="/">
          <img src={companyLogo} alt="Picture of the author" width={90} />
        </Navbar.Brand>
        <Nav>
          <div
            style={{ backgroundColor: 'gray', borderRadius: '8px',cursor:'pointer',marginTop:'-4px' }}
            onClick={handleDarkMode}
          >
            {isDarkModeActive ? (
              <Sun color="yellow"></Sun>
            ) : (
              <Moon color="white"></Moon>
            )}
          </div>
        </Nav>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} style={{textDecoration:'none'}} href="/">
              <Nav.Link as={Link} href="#features">Avril</Link>
            </Link> */}
            {/* <Nav.Link as={Link} style={{textDecoration:'none'}} href="/">
              <Nav.Link as={Link} href="#pricing">Planes</Link>
            </Link>
            <Nav.Link as={Link} style={{textDecoration:'none'}} href="/">
              <Nav.Link as={Link} href="#pricing">Contáctenos</Link>
            </Link> */}
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <Nav.Link as={Link} href="#features">¿Que es Avril?</Link>
                            <Nav.Link as={Link} href="#pricing">Planes</Link>
                            <Nav.Link as={Link} href="#pricing">Contáctenos</Link>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}

          </Nav>
          <Nav>

            <Nav.Link as={Link} style={{ textDecoration: 'none' }} to="/">
              Regresar
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
