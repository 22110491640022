import React from 'react'
import styles from '../../styles/Login.module.css'
import { useForm } from '@mantine/hooks'
import { TextField } from '@mui/material'
import { Text, Paper, Group, Button } from '@mantine/core'
import LoginHeader from '../../components/main/LoginHeader'
import { useNavigate } from 'react-router-dom'
import { notify } from '../../services/utils'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import companyLogo from '../../resources/images/companyLogo.png'
import companyLogoDark from '../../resources/images/companyLogoDark.png'
import useStore from '../../components/zustand'
import { logInApi } from '../api/login'

export default function Login() {
  const navigate = useNavigate()

  const { isDarkModeActive } = useStore()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      company: '',
      nit: '',
      password: '',
      terms: false,
    },

    validationRules: {
      email: (val) => /^\S+@\S+$/.test(val),
      password: (val) => val.length >= 6,
    },
  })

  const successLogin = (data) => {
    if (data.data == 'Inactive') {
      notify('warning', 'Tu cuenta no esta activa.')
    } else {
      if (data.data == 'Error') {
        notify('warning', 'Datos incorrectos, intenta de nuevo.')
      } else {
        notify('success', 'Bienvenido')
        navigate('/dashboard')
      }
    }
  }

  const logIn = async () => {
    if (form.values.password.length > 6) {
      let result = await logInApi(form.values.email, form.values.password)
      if (result.data != null) successLogin(result)
      else notify('warning', 'Datos incorrectos, intenta de nuevo.')
    } else notify('warning', 'La contraseña debe tener al menos 6 caracteres.')
  }

  return (
    <div>
      <LoginHeader />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className={styles.center}>
        <Paper radius="md" p="xl" withBorder style={{ textAlign: 'center' }}>
          <img
            src={isDarkModeActive ? companyLogo : companyLogoDark}
            alt="AvrilLogo"
            width={150}
          />
          <br></br>
          <Text size="lg" weight={500}>
            Bienvenido al admin de AvrilApp
          </Text>
          <br></br>
          <form onSubmit={form.onSubmit(() => {})}>
            <Group direction="column" grow>
              <TextField
                variant="standard"
                required
                label="Correo"
                placeholder="correo@email.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue('email', event.currentTarget.value)
                }
                error={form.errors.email && 'Correo inválido'}
              />

              <FormControl variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Contraseña *
                </InputLabel>
                <Input
                  autoComplete="off"
                  required
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) =>
                    form.setFieldValue('password', event.currentTarget.value)
                  }
                />
              </FormControl>
            </Group>

            <Group position="apart" mt="xl">
              <Button
                type="submit"
                onClick={() => logIn()}
                style={{ width: '100%' }}
              >
                Ingresar
              </Button>
            </Group>
          </form>
        </Paper>
      </div>
    </div>
  )
}
