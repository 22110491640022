import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/admin/menu'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Edit } from 'tabler-icons-react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import { TextField } from '@mui/material'
//Token Verification
import { verifiedToken } from '../../services/token.service'
import adminDecodeToken from '../../services/adminDecodeToken.service'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import useStore from '../../components/zustand'
import { GetCompaniesApi } from '../api/companies'
import {
  GetDebitNoteNumerationApi,
  UpdateDebitNoteNumerationApi,
} from '../api/debitnote'
import AdminMenus from '../../components/admin/adminMenus'

export default function DebitNote() {
  const navigate = useNavigate()

  //Clients Companies
  const [companies, setCompanies] = useState([])

  //Clients Numeration
  const [numeration, setNumeration] = useState([])

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //COMPANY ID
  const [companyId, setCompanyId] = React.useState(0)

  const putForm = useForm({
    initialValues: {
      id: 0,
      prefix: '',
      numerationStart: 0,
      createdAt: '',
      updatedAt: '',
      deleted: false,
    },
  })

  //GETS HOSE FIELDS
  const getNumerationData = async (
    id,
    prefix,
    numerationStart,
    createdAt,
    updatedAt,
    deleted,
  ) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('prefix', prefix)
    putForm.setFieldValue('numerationStart', numerationStart)
    putForm.setFieldValue('createdAt', createdAt)
    putForm.setFieldValue('updatedAt', updatedAt)
    putForm.setFieldValue('delted', deleted)
    setModalPutShow(true)
  }

  const getClientCompanies = async () => {
    let result = await GetCompaniesApi()
    if (result.data != null) setCompanies(result.data)
    else notify('warning', 'No se encontraron compañias.')
  }
  const handleCompanyId = async (companyId) => {
    setCompanyId(companyId)
    await getClientCompanyNumeration(companyId)
  }

  const getClientCompanyNumeration = async (DebitNoteNumerationId) => {
    let result = await GetDebitNoteNumerationApi(DebitNoteNumerationId)
    if (result.data != null) setNumeration(result.data[0])
    else notify('warning', 'No se encontró la numeración.')
  }

  const updateNumeration = async () => {
    if (putForm.values.prefix == '' || putForm.values.numerationStart == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      let result = await UpdateDebitNoteNumerationApi(
        putForm.values.id,
        putForm.values.numerationStart,
        putForm.values.prefix,
      )
      if (result.data == 'DebitNoteNumeration updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getClientCompanyNumeration(putForm.values.id)
      } else notify('warning', 'No se pudo actualizar la numeración.')
    }
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()
    if (result == false) {
      navigate('/')
    } else {
      await getClientCompanies()
      // await getClientHoses(token.companyId, token.branchId)
    }

    // check if user have admin rights
    let adminToken = await adminDecodeToken()
    if (adminToken.roleId != 1) {
      navigate('/')
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Container>
        <AdminMenus buttonId={4} />
        {/* <h2>Numeración para notas débito</h2> */}
        <br></br>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Selecciona una empresa
          </InputLabel>
          <MuiSelect
            value={companyId}
            label="Seleciona Empresa"
            onChange={(e) => handleCompanyId(e.target.value)}
          >
            {companies.map((company, index) => {
              return (
                <MenuItem value={company.id} key={index}>
                  {company.name}
                </MenuItem>
              )
            })}
          </MuiSelect>
        </FormControl>
        <br></br>
      </Container>
      {companyId == 0 ? null : (
        <Container>
          <h3>{companies[parseInt(companyId) - 1].name}</h3>
          <br></br>
          <Row xs={1} md={3} className="g-4">
            <Col>
              <Paper withBorder radius="md" p="xs">
                <br></br>
                <Button
                  style={{
                    float: 'right',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() =>
                    getNumerationData(
                      numeration.id,
                      numeration.prefix,
                      numeration.numerationStart,
                      numeration.createdAt,
                      numeration.updatedAt,
                      numeration.deleted,
                    )
                  }
                >
                  <Edit size={30} color="#000" />
                </Button>
                <Text
                  color="black"
                  size="xl"
                  transform="uppercase"
                  weight={700}
                >
                  Numeración
                </Text>
                <Text
                  color="dimmed"
                  size="ml"
                  transform="uppercase"
                  weight={700}
                >
                  Prefijo: {numeration.prefix}
                </Text>
                <Text
                  color="dimmed"
                  size="ml"
                  transform="uppercase"
                  weight={700}
                >
                  Numeración actual: {numeration.numerationStart}
                </Text>
                <Text
                  color="dimmed"
                  size="ml"
                  transform="uppercase"
                  weight={700}
                >
                  Creación el: {numeration.createdAt}
                </Text>
                <Text
                  color="dimmed"
                  size="ml"
                  transform="uppercase"
                  weight={700}
                >
                  Modificado el: {numeration.updatedAt}
                </Text>
                <br></br>
              </Paper>
            </Col>
          </Row>
        </Container>
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="text"
                      label="Prefijo"
                      placeholder="Prefijo"
                      value={putForm.values.prefix}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'prefix',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Número actual"
                      placeholder="Número actual"
                      value={putForm.values.numerationStart}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'numerationStart',
                          parseInt(event.currentTarget.value),
                        )
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateNumeration()}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <br></br>
    </div>
  )
}
