import React from 'react'
import { useNavigate } from 'react-router-dom'
import companyLogo from '../../resources/images/companyLogo.png'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { logOutApi } from '../../pages/api/login'
import useStore from '../zustand'
import { Moon, Sun } from 'tabler-icons-react'

export default function AdminMenu() {
  const navigate = useNavigate()

  const logOut = async () => {
    let result = await logOutApi()
    if (result.data == 'logged out') {
      navigate('/')
    }
  }

  const { isDarkModeActive, darkMode, setIsDarkModeActive } = useStore()

  const handleDarkMode = () => {
    darkMode.toggle()
    setIsDarkModeActive(!isDarkModeActive)
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1000,
        }}
      >
        <Navbar
          collapseOnSelect
          fixed="top"
          expand="xxl"
          style={{
            backgroundColor: '#212121',
            color: 'white',
            position: 'sticky',
            top: '0',
          }}
          variant="dark"
        >
          <Container>
            <Navbar.Brand to="/dashboard">
              <img src={companyLogo} alt="Picture of the author" width={90} />
            </Navbar.Brand>
            <Nav>
              <div
                style={{
                  backgroundColor: 'gray',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  marginTop: '-4px',
                }}
                onClick={handleDarkMode}
              >
                {isDarkModeActive ? (
                  <Sun color="yellow"></Sun>
                ) : (
                  <Moon color="white"></Moon>
                )}
              </div>
            </Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/dashboard">
                  Inicio
                </Nav.Link>
                <NavDropdown title="Clientes" id="collasible-nav-dropdown">
                  <Nav.Link
                    as={Link}
                    to="/clients"
                    style={{ color: '#000000' }}
                  >
                    Empresas
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/branches"
                    style={{ color: '#000000' }}
                  >
                    Sucursales
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/resolutions"
                    style={{ color: '#000000' }}
                  >
                    Resoluciones
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/creditnote"
                    style={{ color: '#000000' }}
                  >
                    Numeración nota crédito
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/debitnote"
                    style={{ color: '#000000' }}
                  >
                    Numeración nota débito
                  </Nav.Link>
                  {/* <NavDropdown.Divider />
              <NavDropdown.Item as={Nav.Link} to="#action/3.4">
                Separated Nav.Link
              </NavDropdown.Item> */}
                </NavDropdown>
              </Nav>
              <Nav>
                {/* <Nav.Link as={Link} to="#deets">More deets</Nav.Link> */}
                <Nav.Link eventKey={2} onClick={() => logOut()}>
                  Salir
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}
