import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'

export const logOutApi = async () => {
  try {
    let result = await fetch(`${apilUrl}/master-users/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al cerrar sesión.')
  }
}

export const logInApi = async (email, password) => {
  try {
    let logIngData = {
      username: email,
      password: password,
    }
    let result = await fetch(`${apilUrl}/master-users/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logIngData),
    }).then((response) => response.json())
    return result
  } catch (error) {
    notify('error', 'Error al iniciar sesión.')
  }
}
