import React, { useEffect } from 'react'
import Head from 'next/head'
import styles from '../../styles/Home.module.css'
import Menu from '../../components/admin/menu'
import { createStyles } from '@mantine/core'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import companyLogo from '../../resources/images/companyLogo.png'
//Token Verification
import { verifiedToken } from '../../services/token.service'
import adminDecodeToken from '../../services/adminDecodeToken.service'

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: 180,
    paddingBottom: 130,
    backgroundImage:
      'url(https://images.unsplash.com/photo-1573164713988-8665fc963095?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=980&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '@media (max-width: 520px)': {
      paddingTop: 80,
      paddingBottom: 50,
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  title: {
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: 'center',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    '@media (max-width: 520px)': {
      fontSize: 28,
      textAlign: 'left',
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },

  description: {
    color: theme.colors.gray[0],
    textAlign: 'center',

    '@media (max-width: 520px)': {
      fontSize: theme.fontSizes.md,
      textAlign: 'left',
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.5,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    '@media (max-width: 520px)': {
      flexDirection: 'column',
    },
  },

  control: {
    height: 42,
    fontSize: theme.fontSizes.md,

    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    '@media (max-width: 520px)': {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },

  secondaryControl: {
    color: theme.white,
    backgroundColor: 'rgba(255, 255, 255, .4)',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .45) !important',
    },
  },
}))

export default function Dashboard() {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  const tokenVerified = async () => {
    let result = await verifiedToken()

    if (result == false) {
      navigate('/')
    }

    // check if user have admin rights
    let adminToken = await adminDecodeToken()
    if (adminToken.roleId != 1) {
      navigate('/')
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  return (
    <div>
      <Menu />
      <div className={styles.container}>
        <Head>
          <title>Create Next App</title>
          <meta name="description" content="Generated by create next app" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <main className={styles.main}>
          <h1 className={styles.title}>
            Bienvenido al administrador de <a>AvrilApp</a>
          </h1>

          <p className={styles.description}>
            Accesos directos{' '}
            {/* <code className={styles.code}>pages/index.js</code> */}
          </p>

          <div className={styles.grid}>
            <Link
              className={styles.card}
              style={{ textDecoration: 'none' }}
              to="/clients"
            >
              <h3>Clientes &rarr;</h3>
              <p>Aca podras administrar tus clientes.</p>
            </Link>
            <Link
              className={styles.card}
              style={{ textDecoration: 'none' }}
              to="/branches"
            >
              <h3>Sucursales &rarr;</h3>
              <p>Aca podras administrar las sucursales de tus clientes.</p>
            </Link>
            <Link
              className={styles.card}
              style={{ textDecoration: 'none' }}
              to="/resolutions"
            >
              <h3>Resoluciones &rarr;</h3>
              <p>Aca podras administrar las resoluciones de facturación de cada sucursal.</p>
            </Link>
            <Link
              className={styles.card}
              style={{ textDecoration: 'none' }}
              to="/creditnote"
            >
              <h3>Numeración para Nota crédito &rarr;</h3>
              <p>
                Aca podras administrar la numeración para crear notas crédito.
              </p>
            </Link>
            <Link
              className={styles.card}
              style={{ textDecoration: 'none' }}
              to="/debitnote"
            >
              <h3>Numeración para Nota débito &rarr;</h3>
              <p>
                Aca podras administrar la numeración para crear notas débito.
              </p>
            </Link>
            {/* <a
              href="https://github.com/vercel/next.js/tree/canary/examples"
              className={styles.card}
            >
              <h3>Usuarios &rarr;</h3>
              <p>Aca podras administrar a los usuarios administradores.</p>
              <br></br>
            </a> */}
          </div>
        </main>

        <footer className={styles.footer}>
          <a
            href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            Copyright © 2023 AGestion TI SAS{' '}
            <span className={styles.logo}>
              <img src={companyLogo} alt="Vercel Logo" width={90} />
            </span>
          </a>
        </footer>
      </div>
    </div>
  )
}
